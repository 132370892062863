import React from 'react';
import { Lang } from '#constants/translations';

export interface LanguageContextProps {
    lang: Lang;
    setLang: (lang: Lang) => void;
    debug: boolean;
}

export default React.createContext<LanguageContextProps>({
    lang: 'en',
    setLang: (lang: Lang) => {
        console.warn('Trying to set language', lang);
    },
    debug: false,
});
